import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Header from '../components/header/header'
import PageContainer from '../components/pages/page-container'
import Footer from '../components/layout/footer'
import CookieBox from '../components/cookie/cookie-box'
import '../components/pages/downloads.scss'

const DownloadsPage = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | Downloads page</title>
        <body className="downloads" />
      </Helmet>
      <div className="downloadspage">
        <Header darkTheme />
        <CookieBox />
        <PageContainer>
          <h3>Downloads page</h3>
          <div className="title">Journals</div>
          <div className="row">
            <Link
              target="_blank"
              className="download-item"
              to={`${process.env.GATSBY_SHOPIFY_CDN_URL}/DARC_JOURNAL_VOL_1.pdf`}
            >
              <img
                src={`/new/downloads/journals/DARC_JOURNAL_VOL_1_Thumbnail.jpg`}
                alt="Journal vol 1"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`${process.env.GATSBY_SHOPIFY_CDN_URL}/DARC_JOURNAL_VOL_2.pdf`}
            >
              <img
                src={`/new/downloads/journals/DARC_JOURNAL_VOL_2_Thumbnail.jpg`}
                alt="Journal vol 2"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`${process.env.GATSBY_SHOPIFY_CDN_URL}/DARC_JOURNAL_VOL_3.pdf`}
            >
              <img
                src={`/new/downloads/journals/DARC_JOURNAL_VOL_3_Thumbnail.jpg`}
                alt="Journal vol 3"
              />
            </Link>
          </div>
          <div className="title">Desktop</div>
          <div className="row">
            <Link
              target="_blank"
              className="download-item"
              to={`/new/downloads/jpeg/DARC_MONO_1_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_1_Desktop.jpg`}
                alt="Desktop download image 1"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`/new/downloads/jpeg/DARC_MONO_2_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_2_Desktop.jpg`}
                alt="Desktop download image 2"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`/new/downloads/jpeg/DARC_MONO_3_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_3_Desktop.jpg`}
                alt="Desktop download image 3"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`/new/downloads/jpeg/DARC_MONO_4_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_4_Desktop.jpg`}
                alt="Desktop download image 4"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`/new/downloads/jpeg/DARC_MONO_5_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_5_Desktop.jpg`}
                alt="Desktop download image 5"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`{/new/downloads/jpeg/DARC_MONO_6_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_6_Desktop.jpg`}
                alt="Desktop download image 6"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`{/new/downloads/jpeg/DARC_MONO_7_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_7_Desktop.jpg`}
                alt="Desktop download image 7"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`{/new/downloads/jpeg/DARC_MONO_8_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_8_Desktop.jpg`}
                alt="Desktop download image 8"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`{/new/downloads/jpeg/DARC_MONO_9_Desktop.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_9_Desktop.jpg`}
                alt="Desktop download image 9"
              />
            </Link>
          </div>
          <div className="title">Mobile</div>
          <div className="row">
            <Link
              target="_blank"
              className="download-item"
              to={`{/new/downloads/jpeg/DARC_MONO_5_Mobile.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_5_Mobile.jpg`}
                alt="Mobile download image 1"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`{/new/downloads/jpeg/DARC_MONO_6_Mobile.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_6_Mobile.jpg`}
                alt="Mobile download image 2"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`{/new/downloads/jpeg/DARC_MONO_7_Mobile.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_7_Mobile.jpg`}
                alt="Mobile download image 3"
              />
            </Link>
            <Link
              target="_blank"
              className="download-item"
              to={`{/new/downloads/jpeg/DARC_MONO_8_Mobile.jpg`}
            >
              <img
                src={`/new/downloads/jpeg/DARC_MONO_8_Mobile.jpg`}
                alt="Mobile download image 4"
              />
            </Link>
          </div>
        </PageContainer>
        <Footer />
      </div>
    </>
  )
}

export default DownloadsPage
